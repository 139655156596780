import React from 'react';
import '../assets/stylesheets/Loading.scss';

const Loading = ({ isLoadingComplete }) => {
  return (
    <div className={`loading ${isLoadingComplete ? 'loading-complete' : ''}`}>
      <img src={require('../assets/images/loading/typing.gif')} alt="Loading" />
    </div>
  );
}

export default Loading;
