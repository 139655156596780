import React from "react";


const LinkIcon = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 1024 1024" enable-background="new 0 0 1024 1024" >
<path fill="#000000" opacity="1.000000" stroke="none"
	d="
M558.915649,928.567322
	C548.643921,929.386536 538.759827,929.962585 528.900879,930.826050
	C516.020325,931.954102 503.036255,931.642151 490.227112,933.831177
	C484.843292,934.751282 479.307220,933.516418 473.872528,934.631042
	C463.167664,936.826538 452.203857,936.680176 441.386749,937.362976
	C429.067810,938.140503 416.961456,941.761169 404.438171,940.121460
	C401.934814,939.793701 399.236542,940.996948 396.622589,941.462952
	C390.048859,942.634766 383.379913,941.032471 376.917542,941.682312
	C367.179108,942.661682 357.455505,943.320679 347.695129,943.425110
	C335.621124,943.554199 323.600098,944.777649 311.459900,944.157715
	C305.620209,943.859497 299.606750,943.145447 293.401459,944.916565
	C288.364960,946.354065 282.429047,945.590454 276.817749,944.973816
	C267.551239,943.955505 258.126251,944.661743 248.855209,944.147217
	C239.059280,943.603699 229.128922,942.958313 219.542938,943.795776
	C207.455551,944.851868 195.686676,942.691162 183.838593,942.229187
	C175.184662,941.891785 166.733978,943.395020 158.197235,942.807129
	C144.083572,941.835144 130.409790,939.415894 119.379364,929.523621
	C115.930374,926.430603 113.157845,927.474487 110.403877,930.600769
	C108.120041,927.889526 106.705116,925.125305 105.777626,922.128479
	C105.161087,920.136292 103.908913,918.606750 102.587425,917.113770
	C101.645584,916.049805 100.513718,916.355774 99.398483,916.938293
	C98.180359,917.574585 97.817825,918.419556 98.226158,919.803406
	C98.904045,922.100525 100.244835,924.300781 99.639816,927.681641
	C95.191391,925.736694 93.767204,922.016907 92.843964,918.434692
	C90.663376,909.973755 88.779587,901.461426 86.266663,893.062317
	C85.229256,889.594910 84.348839,884.947083 85.348679,880.749023
	C85.730469,879.145996 86.266281,877.507568 84.875702,875.680908
	C80.857300,876.961792 79.012383,880.049377 79.635666,883.886292
	C80.800240,891.055359 80.008179,898.039185 79.055946,905.351257
	C74.853050,905.132446 73.777374,902.441772 73.470795,899.136902
	C72.771477,891.598206 72.240242,884.041565 71.381821,876.521790
	C71.155205,874.536621 70.915688,872.578613 70.917549,870.581177
	C70.925995,861.506165 69.718925,852.570679 68.350044,843.606873
	C67.660187,839.089600 67.191826,834.356567 67.207855,829.829895
	C67.242241,820.114746 64.876122,810.719666 64.487083,801.049255
	C64.022270,789.495239 61.556969,778.035645 60.403828,766.490662
	C59.568039,758.122986 58.542221,749.768311 58.459057,741.306396
	C58.401077,735.407776 56.688950,729.536133 56.487770,723.626160
	C55.972183,708.479919 53.401749,693.502502 53.361374,678.296997
	C53.339432,670.032532 52.410210,661.687622 51.509613,653.444092
	C50.458679,643.824463 51.242981,634.184998 50.196762,624.591431
	C48.544338,609.439148 49.267696,594.185059 48.540157,579.002625
	C48.083752,569.478210 47.911419,559.925781 47.064919,550.385315
	C46.178120,540.390686 48.023895,530.158142 47.191788,520.152954
	C46.290192,509.312256 48.572495,498.631073 47.398239,487.806122
	C46.723312,481.584290 47.664452,475.182800 47.928928,468.864319
	C48.378799,458.116516 48.637196,447.354126 49.389900,436.626892
	C50.977924,413.994904 50.501362,391.293152 52.335480,368.647644
	C53.662830,352.259064 54.811745,335.831696 55.926182,319.414062
	C56.576061,309.840149 60.640633,301.033356 62.928776,291.819824
	C63.400925,289.918610 64.677147,288.086212 62.307854,286.270233
	C64.564590,284.656036 68.336075,284.279327 64.890450,280.180573
	C63.491428,278.516357 65.575111,274.674927 68.688988,273.747833
	C70.183975,273.302734 71.670876,273.159149 72.348167,271.632446
	C74.928963,265.814941 78.474350,260.662781 81.998428,255.375488
	C85.008049,250.860031 87.991318,245.954788 90.389549,240.955093
	C93.501701,234.467087 97.418251,228.552490 101.613304,222.804382
	C103.105690,220.759491 104.549072,218.968155 104.990318,216.329773
	C105.639359,212.448944 109.663414,211.920349 112.270218,210.192566
	C122.032043,203.722412 133.771652,203.037643 144.731583,200.604202
	C151.288818,199.148270 158.464554,198.756439 165.449280,198.455338
	C170.526031,198.236496 175.407150,196.632843 180.231506,199.408798
	C180.571381,199.604355 181.478287,199.504318 181.616638,199.258057
	C184.266968,194.540985 188.724182,197.406021 192.290009,196.570297
	C201.552094,194.399521 211.070953,194.278671 220.406494,193.916733
	C224.233292,193.768356 228.400192,192.494720 232.658264,193.948486
	C236.871170,195.386871 241.293671,192.177612 245.831329,192.651855
	C254.823273,193.591660 263.875702,193.033707 272.911377,194.306625
	C278.040527,195.029190 283.589935,195.240921 288.959900,193.826981
	C294.809631,192.286713 300.707031,194.626862 306.687103,194.147751
	C317.411652,193.288528 328.168365,194.945251 338.861145,194.830017
	C348.933502,194.721466 359.075714,194.506882 369.169647,194.119308
	C377.400085,193.803268 385.819916,194.739624 394.153320,195.169968
	C397.654724,195.350769 401.040924,195.373535 404.430939,194.411972
	C407.433868,193.560196 410.207520,195.264633 413.373138,195.138199
	C427.568268,194.571152 441.798828,194.615112 456.017914,195.521255
	C463.275238,195.983749 470.612854,195.153671 477.875305,195.576157
	C488.521027,196.195450 499.164368,196.203110 509.833710,197.557663
	C521.386780,199.024429 532.403198,202.491638 543.639038,204.937592
	C554.675659,207.340210 565.514648,211.356979 575.948364,216.201920
	C581.928284,218.978745 588.122986,221.294342 593.868042,224.599869
	C597.548706,226.717590 599.698181,229.345886 598.165771,233.948776
	C597.610718,235.615952 597.312561,237.732285 597.842346,239.330246
	C599.578308,244.565277 597.197693,246.436279 592.645996,248.577164
	C578.566833,255.199265 563.791870,259.487030 548.646606,262.363586
	C538.624512,264.267090 528.283325,265.032379 518.318420,266.844147
	C509.592072,268.430756 500.814453,266.019073 492.333588,268.924133
	C490.325409,269.612030 487.762451,268.701965 485.457306,268.497284
	C476.833771,267.731720 468.318634,268.741486 459.733948,269.261871
	C451.877228,269.738068 443.658173,270.054077 435.511383,268.851898
	C427.869537,267.724213 419.901459,268.998413 412.089325,268.777100
	C404.985168,268.575867 398.009796,268.926025 390.912872,269.650269
	C383.924652,270.363373 376.666962,268.803253 369.566254,267.926849
	C364.223236,267.267334 359.015289,267.277313 353.857544,268.924988
	C351.655121,269.628571 349.594391,268.505615 347.465576,268.287537
	C342.632385,267.792419 337.853607,268.817627 333.043976,268.888550
	C328.061584,268.962036 323.204285,267.871155 318.206024,268.265656
	C312.362701,268.726898 306.446869,268.181274 300.568970,268.329163
	C297.558289,268.404968 294.738678,268.288635 291.760925,267.416168
	C287.190948,266.077179 282.509735,268.024597 277.726959,267.792328
	C268.803955,267.358917 259.878967,268.810944 250.816681,268.061035
	C244.001022,267.497009 236.967911,268.661682 230.117615,269.651978
	C222.242035,270.790405 214.312912,270.376862 206.431076,271.151398
	C200.316971,271.752197 194.356964,273.229004 188.202911,273.751678
	C184.230301,274.089111 180.640930,273.618896 176.963303,272.817261
	C173.966354,272.163971 173.101074,273.176636 173.163162,276.105194
	C173.317886,283.403046 177.001892,289.887634 177.756927,297.024323
	C178.119919,300.455139 179.284088,304.040649 175.709625,306.815826
	C177.310974,308.156738 178.423004,309.237671 178.307938,311.411407
	C177.197708,332.385345 176.341385,353.372803 175.224426,374.346375
	C174.581207,386.424194 174.429062,398.617523 172.524979,410.513855
	C170.602722,422.523712 173.005341,434.507446 171.034210,446.521027
	C170.130966,452.026062 171.862152,457.957001 168.597198,463.297272
	C167.799011,464.602844 168.882233,466.194305 169.171722,467.650726
	C169.847992,471.053009 170.605179,474.401184 168.948792,477.846313
	C167.375320,481.119019 170.396683,483.913574 170.169342,487.379883
	C168.672699,510.200409 169.070770,533.060791 169.698929,555.908569
	C170.066742,569.287170 171.540970,582.704529 170.879669,596.023132
	C170.196747,609.777222 172.765198,623.304504 171.997330,637.102234
	C171.577286,644.649963 173.270477,652.819824 174.565063,660.634155
	C175.354462,665.399292 176.103943,670.114929 175.193100,674.973755
	C173.781067,682.506165 176.931259,689.676697 177.107773,697.158508
	C177.478882,712.889099 179.614212,728.527893 180.690567,744.235535
	C181.195312,751.601379 182.464493,758.998962 183.259964,766.403015
	C184.524979,778.177551 186.728409,789.891907 187.291870,801.691040
	C188.025604,817.055664 190.826569,832.181458 191.655640,847.534973
	C191.944809,852.890015 192.372894,858.282715 192.569702,863.676453
	C192.774719,869.295105 196.297516,871.665283 201.675186,869.570923
	C205.256973,868.176025 209.027832,868.927917 212.307068,869.231628
	C220.628021,870.002441 228.971237,868.140747 237.066742,869.434448
	C246.286224,870.907776 255.480988,868.971069 264.534363,870.436218
	C267.564789,870.926697 270.229645,869.910156 273.182770,870.118164
	C282.431427,870.769470 291.776093,870.962463 301.028778,870.412292
	C311.488312,869.790283 322.127136,871.504700 332.386017,868.516663
	C335.116669,867.721252 336.763123,869.975220 339.447479,869.632568
	C345.761841,868.826355 352.400421,867.643433 358.380737,867.705994
	C369.964813,867.827087 381.234772,865.786682 392.653656,865.149902
	C400.120636,864.733521 407.810272,863.442383 415.166595,863.018005
	C422.460327,862.597168 429.864716,859.158691 437.281372,861.861084
	C441.633057,863.446655 445.582703,860.905823 449.844543,860.836304
	C457.857269,860.705505 465.782684,858.750366 474.011353,859.247925
	C477.336975,859.449036 481.070374,857.645447 484.713013,857.498474
	C493.359344,857.149414 501.935547,855.788208 510.620148,855.942017
	C518.948792,856.089478 527.235046,854.335632 535.600647,855.309875
	C538.436340,855.640198 539.974365,852.282043 542.897644,852.563354
	C557.316406,853.950867 571.391235,849.217712 585.897644,850.230957
	C590.192627,850.530884 595.156799,849.662537 599.822998,848.721375
	C604.899658,847.697449 610.335693,846.772278 615.495667,846.614624
	C624.393494,846.342712 633.035278,843.942139 642.022461,844.407532
	C646.899780,844.660034 651.916687,842.156433 656.798645,842.391357
	C663.626526,842.720093 669.996582,839.606750 677.039001,840.955627
	C680.391907,841.597717 684.347290,839.369080 688.291199,840.348450
	C692.736877,841.452393 697.254395,841.436523 701.587769,839.639832
	C703.970093,838.652039 704.979858,841.332581 706.394714,841.509888
	C708.695496,841.338257 708.682800,839.109375 710.689514,838.504028
	C713.931946,841.056213 717.740723,840.440735 721.458862,838.728027
	C727.070984,836.143005 732.783508,838.465942 738.316284,838.730530
	C744.764038,839.038879 750.709473,835.223572 757.291199,837.438904
	C759.821289,838.290466 760.632446,835.769714 760.994934,833.321594
	C761.789490,827.955505 759.831787,822.880493 759.765259,817.582764
	C759.611328,805.326416 759.683838,793.037964 758.215515,780.834106
	C755.816528,760.894409 755.314270,740.792664 752.443359,720.859131
	C751.115295,711.638123 750.475403,702.109009 750.374329,692.626404
	C750.311462,686.737549 749.624207,680.726746 748.803955,674.839722
	C746.854431,660.847595 748.711670,646.985413 749.557251,633.072693
	C750.281677,621.154541 753.029480,609.453735 753.240295,597.482849
	C753.269287,595.837097 753.502197,594.103333 754.076599,592.573975
	C757.342957,583.876648 757.074341,574.594055 758.723511,565.637512
	C760.673218,555.048523 762.209778,544.381287 764.858093,533.918579
	C766.439087,527.672363 767.608887,521.322021 768.960144,515.017700
	C769.489014,512.550049 770.227783,510.208923 772.962524,508.455719
	C773.762878,513.161255 770.285278,517.395081 772.034607,521.966919
	C774.463989,521.005554 773.508728,519.043335 773.899414,517.604919
	C777.367981,504.832489 777.495239,491.309509 782.421448,478.883575
	C784.845276,472.769592 788.318359,472.763214 794.366028,475.239227
	C804.793274,479.508240 807.765991,487.973053 809.538574,497.638275
	C810.213074,501.315796 813.723450,503.035156 815.251831,506.136871
	C819.961182,515.693909 823.526917,525.716370 827.269653,535.659180
	C835.063721,556.364319 843.006409,577.022888 850.244995,597.923157
	C853.143921,606.293091 857.329102,614.287354 858.050659,623.485596
	C858.354065,627.354614 861.764954,630.605652 863.204285,634.593628
	C866.820862,644.614380 869.003967,654.779907 870.338013,665.321106
	C872.285645,680.710022 872.156921,696.282349 874.503784,711.581055
	C875.930237,720.879700 875.008057,730.341797 877.569641,739.593079
	C878.744751,743.836914 878.676147,748.845459 877.578796,753.417847
	C877.192444,755.027588 876.925903,756.121948 878.252563,757.402954
	C878.853638,757.983215 879.239868,759.341431 879.010254,760.142212
	C876.282410,769.654785 880.853821,778.676941 881.018494,788.088501
	C881.215881,799.365906 880.973938,811.018250 883.113525,822.058777
	C885.634827,835.069031 884.781555,848.559448 889.272034,861.242371
	C890.411926,864.462036 890.430176,868.078796 890.962891,871.506958
	C887.401855,872.259583 888.931091,872.336304 888.785950,875.230957
	C888.561218,879.711304 894.585632,884.328308 888.650757,888.829285
	C887.798218,889.475952 888.385559,892.238037 888.567078,893.994263
	C889.439331,902.436401 885.963196,905.886963 877.744812,904.273499
	C875.408875,903.814880 874.653076,905.037964 874.029907,906.572144
	C872.784546,909.638245 870.862854,910.380493 867.734985,909.281799
	C864.036316,907.982605 861.310425,908.389709 859.736633,913.024231
	C857.991882,918.162292 851.916931,919.214111 847.809021,915.654236
	C846.480774,914.503235 844.943054,913.739563 843.307861,913.685547
	C834.539429,913.395752 825.820068,911.709778 817.010681,912.672546
	C813.084290,913.101562 810.573120,914.609314 813.050598,919.525696
	C809.670776,919.443420 806.767883,919.878906 807.000244,915.397766
	C807.094482,913.580566 805.446106,912.879395 804.072632,912.412231
	C801.132874,911.412476 800.125916,914.182190 798.239746,915.825012
	C794.245605,913.140503 789.726013,912.580383 785.026794,913.347534
	C777.583862,914.562561 770.119568,913.822754 762.719116,913.289490
	C749.430298,912.331970 736.228455,913.082825 722.999084,914.087891
	C708.147644,915.216248 693.322937,916.065247 678.492554,918.046143
	C660.792786,920.410217 643.007324,922.966125 625.041687,923.279541
	C618.597717,923.391846 612.181946,924.761841 605.732666,925.000916
	C594.518616,925.416565 583.463013,927.767151 572.178955,927.394958
	C567.907043,927.254028 563.603516,928.071350 558.915649,928.567322
M769.870300,772.634399
	C769.880615,776.715454 770.890198,781.085083 766.422852,783.829102
	C765.973083,784.105286 765.519958,785.148560 765.699707,785.560181
	C768.005859,790.839722 764.331360,797.784851 770.369019,801.655273
	C769.251038,805.955933 767.887573,809.674438 768.518494,814.031982
	C769.122437,818.203674 770.057983,822.483093 770.920654,826.442200
	C771.696838,830.004578 773.277771,832.885132 770.867310,836.162598
	C772.964844,838.238159 775.144531,838.321350 775.545410,835.663879
	C776.127747,831.802979 776.689697,827.381897 774.340942,824.054871
	C771.595215,820.165527 772.754822,815.921631 773.200562,812.367737
	C773.816895,807.453552 773.778687,802.846191 772.822937,798.109436
	C772.562439,796.818481 771.901794,795.435974 772.147217,794.244324
	C773.369873,788.307434 770.484192,782.509094 771.744812,776.558472
	C772.074829,775.000427 771.027832,773.355286 770.201721,771.403198
	C770.529724,770.321472 771.404053,769.504700 771.297302,768.180725
	C770.692444,760.673340 770.263245,753.151611 769.642517,745.645752
	C768.935181,737.091553 768.235046,728.531006 767.193359,720.014648
	C766.956848,718.080200 766.642395,715.304565 763.300476,714.857178
	C761.763855,719.243774 764.638794,723.757019 762.723450,727.774902
	C760.440247,732.564148 762.174316,738.066223 763.482971,741.526733
	C765.908447,747.940430 763.355835,753.856201 764.522339,759.940002
	C765.423096,764.637451 765.794617,769.030823 769.870300,772.634399
M59.813324,640.061340
	C60.791588,643.781494 61.612694,647.631287 60.619469,651.361755
	C58.825089,658.101135 64.639084,664.695801 60.770466,671.394470
	C60.569424,671.742615 60.683598,672.457214 60.915440,672.841797
	C62.806347,675.978027 62.238876,679.349609 61.622662,682.581543
	C60.528904,688.318115 63.210598,692.731995 66.006210,697.985474
	C70.012383,694.416870 68.575577,690.470337 67.676872,687.288025
	C65.818489,680.707458 67.367203,673.657471 64.621666,667.318970
	C63.358807,664.403503 66.451828,663.073242 66.450417,660.467041
	C66.444504,649.523926 64.220482,638.758118 64.107513,627.853027
	C64.040077,621.342834 62.668949,615.006226 63.432762,608.309875
	C64.927742,595.203491 60.895576,582.160828 61.672611,568.961548
	C61.743084,567.764526 61.467731,566.544067 59.878914,566.475769
	C58.760525,566.427673 57.868454,567.027832 57.594864,568.061340
	C56.551079,572.004272 54.814869,575.643372 55.645458,580.131897
	C57.221130,588.646912 59.751938,597.199341 57.310188,606.029602
	C56.908241,607.483154 57.264915,608.946228 58.153847,610.309082
	C61.098816,614.823914 62.224651,619.478149 58.941635,624.384094
	C58.303219,625.338013 57.980698,626.338745 58.193554,627.654297
	C58.818409,631.516235 59.174747,635.421631 59.813324,640.061340
M67.593193,362.444824
	C66.561760,360.463348 67.787941,357.728577 65.396042,355.948395
	C62.219025,360.291687 61.747448,365.056396 62.220123,370.082916
	C62.369587,371.672333 63.043133,373.379639 61.694408,374.849731
	C58.760780,378.047333 59.818607,381.749023 60.028683,385.527008
	C60.323360,390.826569 63.371033,396.170868 60.724674,401.401123
	C58.654324,405.492889 58.193867,409.099243 61.455715,412.417999
	C60.395912,414.561584 57.182659,415.226929 58.634041,418.034363
	C59.156906,419.045746 60.186546,420.240570 61.365486,419.763062
	C63.983078,418.702881 64.818703,416.385437 64.716728,413.676697
	C64.698013,413.179504 64.673874,412.669006 64.759377,412.183502
	C65.629463,407.243896 64.102036,402.414612 65.266304,397.196808
	C67.273399,388.201752 65.898544,378.673584 67.404442,369.447693
	C67.737022,367.410156 69.033272,365.319092 67.593193,362.444824
M77.795761,831.931519
	C79.273338,838.403503 77.817398,844.959351 78.371071,851.489136
	C78.899818,857.725220 81.074326,863.437439 81.923233,870.807617
	C84.188248,866.288818 84.364151,842.972656 82.838646,834.626892
	C82.351212,831.960205 80.570992,830.045166 78.873917,828.123352
	C77.418709,829.047119 77.225449,830.110962 77.795761,831.931519
M63.304714,436.788818
	C63.196793,435.971985 62.852486,435.100830 63.025455,434.348572
	C63.536388,432.126678 63.006683,430.455566 60.547607,430.672852
	C58.290615,430.872253 58.899548,432.557739 59.435799,434.098083
	C59.676403,434.789276 59.641533,435.893402 59.235531,436.444855
	C53.609303,444.087311 59.388233,451.226837 59.483173,459.316559
	C62.866474,456.993286 62.612019,454.235077 62.767643,451.681549
	C63.056461,446.942505 63.261330,442.198334 63.304714,436.788818
M57.454807,536.615662
	C57.884659,537.845520 57.096630,540.006958 59.576954,539.702698
	C61.274902,539.494446 61.360374,538.190186 61.355011,536.710388
	C61.337692,531.929504 61.533295,527.148010 61.540203,522.366699
	C61.544361,519.490112 60.100887,517.522339 56.762241,516.040894
	C55.521149,523.033264 51.850029,529.476746 57.454807,536.615662
M72.085648,742.409973
	C72.926651,737.880188 70.283813,733.568665 71.330475,728.901550
	C71.586861,727.758301 69.518951,725.830627 68.174011,724.661072
	C66.652290,723.337891 64.746422,724.299805 64.973015,725.949768
	C65.807304,732.024841 66.079964,738.308838 69.117844,743.856934
	C70.617836,746.596313 71.517609,745.282532 72.085648,742.409973
M57.662819,480.276825
	C56.893955,483.633575 58.520561,485.961243 60.895889,488.583862
	C63.437733,483.212646 60.870842,478.004639 62.399448,473.133789
	C63.377415,470.017487 61.584339,468.625336 58.770489,468.481140
	C57.420303,472.213654 58.241619,475.956818 57.662819,480.276825
M761.323792,698.970276
	C762.042236,700.295776 761.336914,702.416382 763.488281,702.986023
	C766.471191,698.071167 766.174561,693.882385 762.785400,691.924622
	C762.208679,690.222900 765.550415,689.083923 763.492004,687.151794
	C757.683533,689.856323 760.858948,694.328308 761.323792,698.970276
M70.515335,319.455017
	C70.099884,317.371735 72.439606,315.404633 70.664307,313.195648
	C70.979538,318.974548 61.980602,322.340118 66.470268,329.626862
	C71.160973,327.541656 69.923203,323.465302 70.515335,319.455017
M756.881165,659.974121
	C757.558105,661.723633 756.283691,664.040100 758.393005,665.396729
	C763.119324,661.923767 763.075562,658.942505 758.089539,655.594116
	C757.668640,656.883423 757.268494,658.109375 756.881165,659.974121
M763.673706,571.269775
	C766.679321,573.959656 763.117920,576.699585 763.466187,580.126160
	C768.469238,576.159912 766.374512,571.620789 766.781372,567.107910
	C764.400208,567.878479 763.575317,568.957092 763.673706,571.269775
M61.556156,545.045166
	C60.710625,543.659058 60.018734,541.825073 57.870960,542.989319
	C56.623947,543.665283 56.050312,544.896912 56.572186,546.357056
	C57.116810,547.880981 58.236450,548.919006 59.852859,548.932678
	C62.138935,548.952087 61.680489,547.120422 61.556156,545.045166
M63.096363,422.350983
	C61.181034,421.736176 59.571754,422.751221 57.358852,422.952820
	C58.991737,425.186584 60.260338,426.922028 61.543438,428.677277
	C64.073891,427.055908 63.925198,425.001007 63.096363,422.350983
M76.914330,797.818604
	C76.565018,796.976501 76.555634,795.693665 74.197189,796.698181
	C76.928398,799.251343 73.931732,803.047607 76.953323,806.452637
	C77.825287,803.342590 78.658569,800.927185 76.914330,797.818604
M769.479004,548.831482
	C770.989319,546.298279 770.280273,543.878845 768.899292,541.371460
	C769.203918,543.282043 767.811707,544.653503 767.598938,546.245605
	C767.369141,547.964600 766.997070,549.930786 768.214661,552.260559
	C768.694580,550.884155 768.952087,550.145569 769.479004,548.831482
M759.538696,669.419617
	C757.554810,670.077209 756.912964,671.309021 758.064697,673.156555
	C758.672363,674.131042 759.611023,674.477417 760.498962,673.536621
	C761.829956,672.126343 762.176453,670.687378 759.538696,669.419617
z"/>
<path fill="#000000" opacity="1.000000" stroke="none"
	d="
M798.729858,327.771790
	C795.485840,329.873871 792.783875,331.973206 790.711121,334.926025
	C787.736816,339.163330 783.907471,342.644562 779.443909,345.230621
	C774.083435,348.336182 771.760071,354.096985 767.872925,358.428101
	C761.032532,366.049927 752.924011,371.863739 745.139709,378.205475
	C744.254211,378.926880 742.996521,379.802979 742.865845,380.740356
	C741.991577,387.011780 736.557312,390.357941 732.571777,393.190033
	C727.485962,396.803986 723.549622,401.329987 719.077209,405.372772
	C712.279297,411.517670 706.031128,418.247406 698.714844,423.856171
	C694.939026,426.750732 693.250916,431.487366 689.344421,434.622589
	C681.896362,440.600128 675.046265,447.257385 668.548218,454.410675
	C659.745056,464.101593 650.863647,473.861877 640.444519,482.039917
	C636.339844,485.261719 633.070923,489.575348 629.548218,493.505432
	C623.859558,499.851837 615.047607,501.453400 608.973694,507.194916
	C608.389648,507.747040 606.988831,508.317047 607.059082,508.551422
	C609.200195,515.693115 602.120911,517.314758 599.070923,520.893799
	C595.610962,524.953857 591.133240,528.389221 588.080688,532.818665
	C584.029724,538.696899 578.581543,543.201538 573.692993,548.154541
	C569.453918,552.449524 565.712097,557.298462 560.598938,560.916138
	C554.166321,565.467285 548.448242,571.047913 543.485046,577.289734
	C538.507568,583.549500 532.820496,588.982544 525.473877,592.450378
	C524.774292,592.780579 523.914612,593.327942 523.668884,593.978394
	C520.819946,601.519165 513.678406,605.096680 508.824127,610.652527
	C503.929016,616.255127 496.358643,618.185730 492.551514,624.737915
	C490.804016,627.745361 486.999481,627.489014 484.066650,628.452332
	C480.563019,629.603333 478.559967,631.651001 476.432526,634.817932
	C473.095245,639.786072 468.582062,644.212646 462.962006,646.928040
	C460.814087,647.965881 458.731750,648.800598 457.623413,651.100281
	C457.350250,651.667053 456.788361,652.384277 456.251740,652.490662
	C449.399750,653.849609 445.546204,659.914795 439.712799,662.996094
	C434.665833,665.661804 430.486176,669.981812 425.956543,673.612244
	C418.310791,679.740234 409.542633,684.109375 401.379395,689.416504
	C388.865234,697.552185 375.693390,704.533142 362.312439,710.952759
	C348.266876,717.691223 333.515900,722.960632 319.061646,728.843567
	C318.009552,729.271729 316.335815,730.092407 315.843292,729.675781
	C311.345612,725.871277 306.334656,725.638367 300.935059,726.873840
	C300.245575,727.031677 299.135529,726.651123 298.635651,726.125183
	C295.640350,722.973511 292.766632,719.706238 289.691833,716.300110
	C288.675293,716.037964 288.276001,718.058167 286.346924,717.303162
	C284.761108,713.309753 287.605927,709.979004 289.327545,706.798706
	C294.251526,697.702576 301.463837,690.200439 307.507324,681.877991
	C311.382111,676.542053 315.798767,671.788696 320.102539,666.878235
	C327.448029,658.497314 334.606873,649.783264 341.736389,641.158813
	C346.735931,635.110962 351.386230,628.691833 356.977600,622.985413
	C363.265442,616.568176 369.192749,609.617798 374.789886,602.439941
	C382.386658,592.697815 390.137695,583.009277 399.330353,574.608826
	C401.741150,572.405762 403.410980,569.411743 405.565674,566.905212
	C414.909668,556.035522 424.523041,545.032654 435.403931,536.091125
	C449.195465,524.757690 460.717926,511.256409 474.160797,499.682098
	C478.563843,495.891052 483.288910,492.035645 486.814453,487.174347
	C487.879272,485.706177 489.341858,484.432037 489.192139,482.384399
	C489.092438,481.020935 489.673065,479.954285 490.917023,479.694305
	C494.958038,478.849854 497.131775,475.517181 500.154297,473.286316
	C504.557098,470.036682 507.874207,466.207611 510.601837,461.499176
	C512.247864,458.657745 513.824646,455.315857 517.820984,454.454895
	C519.830444,454.021942 519.024780,451.656128 519.432312,450.044189
	C522.878723,450.987671 524.973328,448.671509 526.963989,446.954712
	C533.568054,441.259003 540.286133,435.614349 546.254089,429.284180
	C552.851135,422.286804 559.739502,415.597900 566.497803,408.773926
	C574.499695,400.694366 582.451355,392.414673 590.868103,384.938934
	C599.214966,377.525238 605.427551,367.946167 614.864990,361.849182
	C620.827942,357.996857 625.098938,352.746643 629.049438,347.050171
	C629.725403,346.075409 631.158997,345.637573 632.217041,344.914398
	C641.477234,338.585754 648.715820,330.271545 655.325745,321.344177
	C658.236145,317.413422 662.629700,315.170837 666.164185,311.928925
	C669.483276,308.884613 671.303162,304.486603 675.585083,302.299316
	C677.423340,301.360291 676.773376,299.250458 676.041138,297.363342
	C675.415649,295.751434 672.730164,294.041840 675.199951,292.161682
	C677.913757,290.095825 677.772339,294.574036 680.295044,294.478149
	C681.441895,291.290344 684.792908,290.719391 687.655457,289.212616
	C690.327332,287.806244 692.969116,286.121155 694.289551,282.775818
	C695.125305,280.658325 697.155273,278.828033 699.360779,277.496399
	C701.775208,276.038635 704.130005,274.419067 704.987305,271.434296
	C705.161865,270.826416 705.502625,270.065430 705.999390,269.780518
	C716.339905,263.849548 722.120789,253.023361 731.489197,246.042740
	C732.630432,245.192398 733.587463,243.765305 734.054749,242.407532
	C735.077759,239.435440 736.500916,237.912537 739.901978,236.838684
	C742.976746,235.867889 745.051147,231.897369 747.208557,229.109573
	C753.572266,220.886383 761.931580,214.656158 768.567505,206.754730
	C769.530518,205.608078 770.362549,204.175751 771.594971,203.463928
	C775.351990,201.293945 769.759644,195.281357 774.624817,194.795334
	C778.426331,194.415558 780.729309,192.975555 782.963074,190.491730
	C783.851318,189.504013 784.810730,188.551422 785.532898,187.448715
	C787.468445,184.493134 786.422668,182.653336 783.114868,182.194077
	C778.314148,181.527527 773.465088,181.681747 768.753540,182.741989
	C756.436646,185.513611 743.770569,184.806747 731.371887,186.714691
	C720.784363,188.343948 710.156006,189.664444 699.438416,190.053436
	C681.844055,190.692001 664.251526,191.511490 646.650818,191.792450
	C637.787354,191.933914 628.880676,191.352768 620.045166,190.538635
	C614.420471,190.020370 610.946899,185.762924 608.293396,181.190765
	C607.123413,179.174896 606.755371,177.164536 603.773315,176.637222
	C600.905396,176.130096 601.087463,173.527802 603.207275,172.338165
	C607.296692,170.043289 609.553711,165.866074 613.521301,163.363464
	C616.567932,161.441772 616.743225,157.050491 620.171814,154.276230
	C626.632751,149.048325 634.140930,145.998657 641.189087,142.252701
	C652.643616,136.164932 665.064758,131.926178 676.818726,126.359573
	C683.139771,123.365913 690.110962,122.842758 696.414551,119.903168
	C706.151978,115.362267 716.276001,111.499336 726.854675,109.724930
	C735.630554,108.252914 744.298767,106.314163 752.929810,104.346687
	C764.129578,101.793686 775.581177,100.786057 786.836243,98.683990
	C796.826843,96.818085 806.865479,93.737076 817.470032,94.052208
	C822.135620,94.190857 827.481445,92.301811 832.190125,90.665123
	C837.658752,88.764343 843.527039,88.627380 848.790283,86.157776
	C850.261597,85.467415 852.108154,85.844032 852.904419,87.104683
	C856.001404,92.008293 860.063354,91.439865 865.044556,90.632416
	C869.607666,89.892731 874.541382,90.695312 879.177734,91.547546
	C880.836548,91.852455 882.043091,92.034447 883.188904,91.048599
	C888.625488,86.371498 894.684998,84.807991 901.650208,86.909737
	C902.697205,87.225616 904.035767,87.217865 905.061279,86.855530
	C910.833862,84.815956 914.147888,85.942131 916.505676,91.244316
	C917.346924,93.135971 919.166016,93.680206 920.499451,94.825676
	C923.704346,97.578728 933.941467,97.204292 936.717163,94.525223
	C940.809753,90.574951 946.060852,91.727203 947.665466,97.312683
	C948.703064,100.924423 950.372253,102.170494 953.726929,100.686066
	C958.785095,98.447876 958.271912,104.280205 960.812317,105.555428
	C966.198975,102.343491 970.873657,104.313347 971.945312,110.589729
	C973.002625,116.782104 973.122620,123.244949 974.312134,129.289658
	C977.176086,143.842194 972.669067,157.903137 972.927246,172.238754
	C973.045349,178.793655 970.805542,185.418518 969.420776,191.966309
	C967.513733,200.983902 965.488342,209.978622 963.361694,218.947113
	C959.813538,233.910324 956.395874,248.911285 952.468506,263.776184
	C946.484802,286.424622 940.122986,308.990875 932.184265,331.036591
	C924.095581,353.498810 916.271790,376.147064 902.240784,395.905060
	C900.802979,397.929688 899.394775,399.589172 896.659668,399.344940
	C896.171326,399.301361 895.441162,399.227570 895.204651,399.499634
	C889.086304,406.540222 881.915894,398.888000 875.179993,400.850464
	C871.811584,401.831818 868.661865,398.290619 867.975037,394.060028
	C867.549316,391.437836 866.854980,388.859924 866.308044,386.256470
	C865.842163,384.039215 865.474426,382.585144 862.633362,381.318542
	C858.659241,379.546692 858.047363,374.430756 858.176453,370.316833
	C858.454468,361.463623 856.162476,352.955566 855.301758,344.265900
	C853.404968,325.117004 851.481812,305.982758 850.648438,286.750793
	C850.533752,284.105316 850.216797,281.446106 849.721191,278.845123
	C849.032104,275.228088 846.374634,274.045319 844.257629,276.743652
	C839.038086,283.396759 830.921875,287.143158 826.535217,294.425110
	C822.643005,300.886169 818.415100,306.852875 811.802551,310.778687
	C811.526611,310.942505 811.184814,311.216797 811.126343,311.493073
	C809.593018,318.744019 802.666138,321.910004 798.729858,327.771790
M862.279968,318.115234
	C860.898376,314.131775 860.883301,310.066528 861.595825,305.969574
	C862.033936,303.450104 861.153931,301.347107 859.947327,298.869415
	C857.014343,304.492554 859.278015,352.710480 863.169678,358.012390
	C864.820740,344.208313 862.047485,331.526733 862.279968,318.115234
M858.841858,264.967468
	C856.821533,265.900146 855.966980,267.694489 855.675415,269.704285
	C854.812500,275.652222 856.742737,281.469330 856.695435,287.404846
	C856.685852,288.600830 857.979126,290.071228 860.370483,290.039703
	C862.553528,285.042297 861.225586,279.554169 861.310791,274.392120
	C861.359192,271.458496 861.921814,267.749817 858.841858,264.967468
z"/>
</svg>
  );
}

export default LinkIcon;
