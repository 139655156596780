import React, { useState, useRef, useEffect } from 'react';
import { sendForm } from '@emailjs/browser';
import '../assets/stylesheets/App.scss';
import '../assets/stylesheets/Contact.scss';
import '../assets/stylesheets/Buttons.scss';
import waveImg from '../assets/images/about/wave.png';
import { motion } from "framer-motion";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function Contact() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const form = useRef();

  useEffect(() => {
    if (formSubmitted) {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        title: "Message Sent!",
        text: "I'll be in touch with you shortly",
        icon: "success",
        buttonsStyling: false,
        textsStyling: false,
        customClass: {
          title: 'header-sm',
          text: 'text-body',
          confirmButton: 'btn-black-rounded',
          closeButton: 'btn-black-rounded'
        }
      }).then(() => setFormSubmitted(false));
    }
  }, [formSubmitted]);

  const isValidEmail = email => /\S+@\S+\.\S+/.test(email);

  const formatLabel = (name) => {
    return name.charAt(0).toUpperCase() + name.slice(1).replace(/([A-Z])/g, ' $1');
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (!value) {
      setValidationErrors(prevErrors => ({ ...prevErrors, [name]: `Enter your ${formatLabel(name).toLowerCase()}.` }));
    } else {
      setValidationErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    }

    if (name === 'email' && value && !isValidEmail(value)) {
      setValidationErrors(prevErrors => ({ ...prevErrors, [name]: 'Enter a valid email.' }));
    }
  };

  const isFormValid = () => Object.values(formData).every(value => value.trim() !== '') &&
    Object.values(validationErrors).every(value => value === '');

  const validateForm = () => {
    const errors = {};
    Object.keys(formData).forEach(key => {
      if (!formData[key]) {
        errors[key] = `Enter your ${formatLabel(key).toLowerCase()}.`;
      } else if (key === 'email' && !isValidEmail(formData[key])) {
        errors.email = 'Enter a valid email.';
      }
    });

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        title: "Oops!",
        text: "Please fill out all fields correctly.",
        icon: "error",
        buttonsStyling: false,
        textsStyling: false,
        customClass: {
          title: 'header-sm',
          text: 'text-body',
          confirmButton: 'btn-black-rounded',
          closeButton: 'btn-black-rounded'
        }
      });
      return;
    }

    sendForm('service_3jb1avs', 'template_zk2g3dv', form.current, 'BXL-fLH8LhypzwrgN')
      .then(() => {
        setFormData({ firstName: '', lastName: '', email: '', message: '' });
        setValidationErrors({});
        setFormSubmitted(true);
      }, (error) => {
        console.error('FAILED...', error.text);
        const MySwal = withReactContent(Swal);
      MySwal.fire({
        title: "Oops!",
        text: "Something went wrong. Please try again.",
        icon: "error",
        buttonsStyling: false,
        textsStyling: false,
        customClass: {
          title: 'header-sm',
          text: 'text-body',
          confirmButton: 'btn-black-rounded',
          closeButton: 'btn-black-rounded'
        }
      });
      });
  };

  return (
    <section id="contact">
      <div className="container-header">
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: "0%" }}
          transition={{ duration: 1, delay: 0.5 }}>
          <h1 className="header-lg">Contact Me</h1>
        </motion.div>
      </div>
      <a href="mailto:info@emiliarojo.com" className="subheader-md">info@emiliarojo.com</a>
      <div id="container-contact">
        <motion.div
          initial={{ x: "-200%", rotate: -360 }}
          animate={{ x: "0%", rotate: 0 }}
          transition={{ type: "spring", stiffness: 100, damping: 10, delay: 0.3 }}
          className="img-rolling-ball"
          style={{ backgroundImage: `url(${waveImg})` }}>
        </motion.div>
        <div id="container-contact-form">
          <form onSubmit={handleSubmit} ref={form}>
            {['firstName', 'lastName', 'email'].map((field) => (
              <div className="floating-input" key={field}>
                <input
                  className="text-body-sm"
                  type={field === 'email' ? 'email' : 'text'}
                  id={field}
                  name={field}
                  placeholder=" "
                  value={formData[field]}
                  onChange={handleChange}
                  onBlur={() => handleChange({ target: { name: field, value: formData[field] } })}
                  autoComplete="off"
                />
                <label htmlFor={field}>{formatLabel(field)}</label>
                {validationErrors[field] && <span className="field-error">{validationErrors[field]}</span>}
              </div>
            ))}
            <div className="floating-input">
              <textarea
                className="text-body-sm"
                id="message"
                name="message"
                placeholder="Your message here..."
                value={formData.message}
                onChange={handleChange}
                onBlur={() => handleChange({ target: { name: 'message', value: formData.message } })}
                autoComplete="off"
              ></textarea>
              {validationErrors.message && <span className="field-error">{validationErrors.message}</span>}
            </div>
            <div id="contact-container-form-btn">
              <button type="submit" className="btn-black-rounded" disabled={!isFormValid()}>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;
