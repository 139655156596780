import React from 'react';
// import {gsap} from 'gsap';
// import {DrawSVGPlugin} from 'gsap/DrawSVGPlugin';

// gsap.registerPlugin(DrawSVGPlugin)

const ArrowLeft = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 1024 1024" x="0" y="0">
      <path fill="#000000" opacity="1.000000" stroke="none"
        d="
      M466.450806,437.979675
        C472.748047,438.294617 478.780212,438.010895 484.531006,437.468262
        C492.751099,436.692627 501.024933,435.418549 509.248688,435.271423
        C521.176880,435.058014 532.923523,433.125183 544.797241,432.639526
        C559.885071,432.022461 574.988708,431.648804 590.055054,430.691010
        C605.225342,429.726654 620.388733,429.776855 635.569031,429.550446
        C653.559021,429.282135 671.461121,430.518005 689.399841,431.048370
        C720.312988,431.962402 751.059998,434.490967 781.636536,439.586029
        C795.566956,441.907379 809.610657,443.378937 823.134705,447.665436
        C827.459534,449.036255 831.452759,451.101990 835.673035,453.875397
        C831.108765,457.218933 826.698547,456.400421 822.569580,456.048431
        C805.611145,454.602875 788.678467,454.964874 771.725952,456.050140
        C766.747070,456.368896 761.735352,456.286530 756.740601,456.226868
        C731.783081,455.928650 706.980042,459.112183 682.050293,459.481384
        C670.490417,459.652588 658.964661,460.569519 647.380310,460.727020
        C632.052124,460.935455 616.731995,463.003448 601.453796,464.684845
        C597.852905,465.081116 594.248657,465.001678 590.636353,465.427551
        C568.586731,468.026855 546.467468,470.027771 524.386108,472.367096
        C503.926941,474.534607 483.504425,477.061340 463.030884,479.074982
        C449.497742,480.406067 436.020538,482.133575 422.680389,484.633514
        C400.831879,488.727844 378.730377,491.426239 357.057861,496.456177
        C340.639130,500.266724 324.157990,503.827362 307.716339,507.507111
        C293.305328,510.732300 279.749908,516.980957 265.008209,518.808472
        C263.346619,519.014404 261.565094,519.727234 260.116028,518.959778
        C253.603271,515.510742 247.347488,517.768860 240.993118,519.400513
        C234.641006,521.031616 225.360214,516.039917 223.495300,509.939117
        C222.372421,506.265778 220.926498,503.197968 216.728210,502.190155
        C214.162949,501.574341 213.532776,499.440247 213.952866,497.063110
        C214.294540,495.129608 215.068756,493.957794 217.572098,493.907745
        C221.773422,493.823822 223.267197,489.982330 223.242203,486.975250
        C223.214188,483.599670 224.907227,481.901367 226.447647,479.885742
        C228.542160,479.700897 227.918716,481.572906 228.711975,482.281982
        C229.000183,482.118134 229.447189,482.023010 229.539246,481.788513
        C232.069214,475.344238 238.589966,475.856964 243.579453,473.769165
        C253.505539,469.615601 264.007721,467.224365 274.490906,465.326660
        C283.043610,463.778351 290.957977,459.287964 300.260498,460.477417
        C303.120209,460.843079 306.713501,457.821686 310.445221,457.348602
        C314.813385,456.794830 319.010406,455.544006 323.569550,455.709778
        C328.016724,455.871490 332.532990,454.326294 336.700104,453.019714
        C341.297943,451.578156 345.408905,451.740265 349.790436,452.718414
        C350.588562,452.896637 351.868347,453.671906 352.048431,453.452209
        C354.892273,449.983856 359.219269,452.028625 362.144409,450.774872
        C367.477509,448.489014 372.958130,448.272095 378.374908,447.349609
        C380.524719,446.983459 382.875885,446.766205 385.374603,447.929565
        C387.563690,448.948730 390.969360,449.526855 392.703827,446.043060
        C392.953430,445.541656 393.785767,445.027222 394.346222,445.029663
        C401.629700,445.061035 408.497620,442.179077 415.891571,442.382111
        C421.944763,442.548309 428.173218,441.806885 434.079712,440.424255
        C441.161560,438.766541 448.281677,440.005280 455.373840,439.214752
        C458.879089,438.824066 462.400360,437.183105 466.450806,437.979675
      z"/>
      <path fill="#000000" opacity="1.000000" stroke="none"
        d="
      M242.665634,431.460236
        C237.783768,439.768341 230.877975,445.827271 224.172775,451.914856
        C214.133392,461.029541 203.998581,470.199860 192.602478,477.547516
        C184.321320,482.886810 177.351669,489.639008 169.719345,495.660767
        C168.155090,496.894989 166.600525,498.193298 165.280350,499.674927
        C161.852417,503.522003 162.630630,506.190338 167.405457,508.365417
        C187.847778,517.677429 209.363770,524.365051 229.758698,533.752197
        C244.107330,540.356323 258.169373,547.584900 272.324280,554.605408
        C273.791565,555.333130 275.040894,556.515747 276.354340,557.534973
        C279.215454,559.755371 280.296875,563.133606 278.869843,565.991638
        C277.462463,568.810242 274.149017,569.413513 270.659851,569.107788
        C257.114014,567.920715 244.235107,563.786011 231.260193,560.240051
        C225.195724,558.582703 219.175705,556.357117 213.310669,554.004578
        C205.440216,550.847778 196.787094,549.944153 189.302414,546.098206
        C180.588593,541.620728 170.804993,541.281250 161.882034,537.660400
        C152.664383,533.919983 143.084091,530.999695 133.521088,528.204163
        C123.613342,525.307861 118.526230,517.268738 112.989929,509.777802
        C110.404549,506.279694 111.435684,501.879089 111.853783,497.686859
        C112.263451,493.579102 113.431564,490.773834 116.908974,487.894562
        C120.481133,484.936890 123.752731,480.806000 124.818344,475.727203
        C124.946297,475.117401 125.163086,474.272675 125.610519,474.021942
        C131.130798,470.928467 133.535934,464.515472 138.930664,461.434113
        C141.292023,460.085358 141.669388,458.433929 141.222137,455.689880
        C144.683762,456.608643 146.651245,456.222534 147.884262,452.302643
        C148.681015,449.769714 152.679535,448.564270 155.028900,446.397644
        C166.914963,435.435944 177.800598,423.519012 188.820114,411.720673
        C193.671387,406.526520 198.866318,401.653687 203.726593,396.467529
        C205.736359,394.323029 206.301483,397.741852 208.010757,397.118073
        C209.233490,395.643555 210.574646,394.026184 211.907440,392.418915
        C218.359283,397.493073 222.151855,397.578705 228.838516,392.686493
        C230.859116,391.208130 233.010101,391.173615 234.095200,392.998230
        C236.565140,397.151581 240.069901,397.627716 244.295822,397.213593
        C246.266937,397.020447 247.835922,398.649719 247.190521,400.377441
        C244.502960,407.571869 248.604279,415.058990 245.964005,422.191895
        C244.868317,425.152008 243.888489,428.154999 242.665634,431.460236
      z"/>
    </svg>
  )
}

export default ArrowLeft;
