import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ParallaxBanner } from 'react-scroll-parallax';
import { motion } from "framer-motion"
import Accordion from './Accordion';
import '../assets/stylesheets/About.scss';
import hat1 from '../assets/images/hats/hat1.webp';
import hat2 from '../assets/images/hats/hat2.webp';
import hat3 from '../assets/images/hats/hat3.webp';
import hat4 from '../assets/images/hats/hat4.webp';
import hat5 from '../assets/images/hats/hat5.webp';
import hat6 from '../assets/images/hats/hat6.webp';
import hat7 from '../assets/images/hats/hat7.webp';
import hat8 from '../assets/images/hats/hat8.webp';
import hat9 from '../assets/images/hats/hat9.webp';
import hat10 from '../assets/images/hats/hat10.webp';
import click from '../assets/images/hats/click.webp';
import tap from '../assets/images/hats/tap.webp';

const hatImages = [hat1, hat2, hat3, hat4, hat5, hat6, hat7, hat8, hat9, hat10];

const About = forwardRef(({meImage, onViewPortfolioClick}, ref) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const clickRef = useRef();
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    setIsTouchDevice(('ontouchstart' in window) || navigator.maxTouchPoints > 0);
  }, []);

  const handleHatClick = (event) => {
    event.stopPropagation();
    clickRef.current.style.opacity = 0;
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % hatImages.length);
  };

  return (
    <ParallaxProvider>
      <section id="about">
        <div id="container-about-a">
          <div className="container-header">
            <motion.div
              initial={{ y: "100%" }}
              animate={{ y: "0%" }}
              transition={{ duration: 1, delay: 0.5}}>
              <h1 className="header-lg text-white">About Me</h1>
            </motion.div>
          </div>
          <div id="container-about-a-info">
            <ParallaxBanner
              layers={[{ image: meImage, speed: -10 }]}
              id="img-about-me"
            />
            <div>
              <p className="text-body text-white">
                My name is Emilia, I am a Mexican-American designer and developer based in Barcelona, Spain.
                As a full-stack developer, UX/UI designer, and graphic designer, I'm a one-woman show.<br></br><br></br>
                My expertise spans from the initial conceptual stages to the final stages of development and design. With a flair for the creative and a solid tech foundation, I make sure every bit of my work is not just easy on the eyes but efficient and user-friendly too.<br></br><br></br>
                Whether I'm collaborating with startups making their mark, established companies looking to innovate, or individual clients with unique visions, my goal is to bring their digital dreams to life.<br></br><br></br>
                Beyond the world of coding and design, I love  to paint, travel, work out, and spend time with my friends, family, and dog.
              </p>
            </div>
          </div>
          <div className="container-header">
            {/* <motion.div
              initial={{ y: "100%" }}
              whileInView={{ y: "0%" }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.5}}> */}
              <h2 className="header-md text-white">Skills</h2>
            {/* </motion.div> */}
          </div>
          <div id="container-about-a-skills">
            <Accordion title="Full-Stack Development">
              <p className="text-body-sm text-white">
                As a full-stack web and software developer, I leverage my understanding of programming languages, paradigms,
                and frameworks to create robust and efficient applications. Utilizing a comprehensive suite of technologies
                including JavaScript, Java, Python, TypeScript, and Ruby for programming, and frameworks such as React and
                Ruby on Rails for development, I harness front-end and back-end tools to craft full-fledged
                applications. My toolkit also extends to databases like PostgreSQL and MongoDB, testing tools like Mocha and
                RSpec, and deployment via platforms such as Heroku and Vercel. With a solid grasp of data structures and algorithms,
                I optimize application performance and architecture. I am skilled in database management and versed in version
                control systems. I emphasize user experience, ensuring the software I develop is not only functional but also
                intuitive and user-friendly.
              </p>
              <p className="text-body-sm text-white"><strong>Technologies I use:</strong></p>
              <ul className="text-body-sm text-white">
                <li><strong>Programming Languages: </strong>JavaScript, Java, Python, C, Ruby, Node.js, TypeScript</li>
                <li><strong>Front-End:</strong></li>
                  <ul>
                    <li><strong>Languages: </strong>JavaScript, Typescript</li>
                    <li><strong>Frameworks/Libraries: </strong>React, Redux, Next.js, Stimulus</li>
                    <li><strong>Markup & Styling: </strong>HTML, CSS, Sass, Bootstrap, Tailwind</li>
                  </ul>
                <li><strong>Back-End:</strong></li>
                  <ul>
                    <li><strong>Languages: </strong>Java, Python, C, Ruby, Node.js</li>
                    <li><strong>Frameworks: </strong>Ruby on Rails,Spring Boot, Express</li>
                  </ul>
                <li><strong>Databases:</strong> PostgreSQL, MongoDB</li>
                <li><strong>Testing:</strong> Mocha, Chai, RSpec, PyTest</li>
                <li><strong>Deployment & Version Control:</strong> Git, Docker, Heroku, Netlify, Vercel, AWS</li>
              </ul>
            </Accordion>
            <Accordion title="UX / UI Design">
              <p className="text-body-sm text-white">
                When it comes to user experience and interface design, my approach is centered on fostering intuitive
                interactions and crafting visually appealing layouts that resonate with users. I employ user-centered
                design principles to map out user journeys, ensuring a seamless flow from one touchpoint to the next.
              </p>
              <p className="text-body-sm text-white"><strong>Design tools I use:</strong></p>
              <ul className="text-body-sm text-white">
                <li><strong>Figma:</strong> For wireframing, prototyping, and collaboration.</li>
                <li><strong>Adobe Illustrator:</strong> For creating vector graphics and design elements.</li>
                <li><strong>Procreate:</strong> For digital sketching, illustrating, and bringing creative concepts to life.</li>
              </ul>
            </Accordion>
            <Accordion title="Graphic Design">
              <p className="text-body-sm text-white">
                As a graphic designer with a focus on digital media, my craft revolves around creating visually striking designs
                that thrive in the online landscape. I specialize in producing digital assets that are not only vibrant
                and attention-grabbing but also optimized for various digital platforms.
              </p>
              <p className="text-body-sm text-white"><strong>Design tools I use:</strong></p>
              <ul className="text-body-sm text-white">
                <li><strong>Adobe Illustrator:</strong> For logo design, branding, and vector illustrations</li>
                <li><strong>Procreate:</strong> For hand-drawn illustrations and artistic compositions.</li>
              </ul>
            </Accordion>
          </div>
        </div>
        <div id="container-about-b" ref={ref}>
          <div id="container-about-b-info">
              <h2 className="header-md text-white">As a designer & developer I wear many <span style={{textWrap: "nowrap"}}><span className="text-hollow">hats</span>.</span></h2>
            <div id="container-about-b-info-btn">
              <button className="btn-white-rounded" onClick={onViewPortfolioClick}>View Portfolio</button>
            </div>
          </div>
          <div id="container-about-b-hats">
            <img src={isTouchDevice ? tap : click} alt={isTouchDevice ? "Tap" : "Click"} id="img-click" ref={clickRef} />
            <img
              src={hatImages[currentImageIndex]}
              alt="About Me"
              onClick={handleHatClick}
            />
        </div>
        </div>
      </section>
    </ParallaxProvider>
  );
});

export default About;
