import React, { forwardRef, useEffect, useRef } from 'react';
import '../assets/stylesheets/Home.scss';
import '../assets/stylesheets/Buttons.scss';
import '../assets/stylesheets/Animations.scss';
// import homeImg from '../assets/images/home/home-img.webp';
// import { motion } from "framer-motion";

const Home = forwardRef(({ onLearnMoreClick, homeImage }, ref) => {
  const pTags = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(entry.target.dataset.anim);
          }
        });
      },
      { threshold: 0.5 }
    );

    pTags.current.forEach(tag => {
      if (tag) observer.observe(tag);
    });

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      pTags.current.forEach(tag => {
        if (tag && observer) observer.unobserve(tag);
      });
    };
  }, []);


  return (
    <section id="home">
      <div id="container-home">
        <div id="home-headers">
        <div className="container-header">
            {/* <motion.div
              initial={{ y: "100%" }}
              animate={{ y: "0%" }}
              transition={{ duration: 1, delay: 2.5}}
              viewport={{ once: true }}
            > */}
              <h1 className="header-lg">Emilia Rojo</h1>
            {/* </motion.div> */}
          </div>
          <h2 className="subheader-lg">Designer & Developer</h2>
        </div>
        <div id="container-home-img">
          <img src={homeImage} alt="Emilia Rojo" />
        </div>
      </div>
      <div id="container-home-info" ref={ref}>
        <div id="container-home-info-text">
          <p ref={(el) => (pTags.current[0] = el)} data-anim="slide-in-left" className="text-bold">Hey! My name's Emilia.</p>
          <p ref={(el) => (pTags.current[1] = el)} data-anim="slide-in-right" className="text-bold">I'm a full-stack developer, UX/UI designer, and graphic designer from the United States currently based in Spain.</p>
          <p ref={(el) => (pTags.current[2] = el)} data-anim="slide-in-bottom" className="text-bold">I bring ideas to life by combining design thinking, programming expertise, and an eye for aesthetics.</p>
          <button onClick={ onLearnMoreClick } className="btn-white-rounded">Learn More</button>
        </div>
      </div>
    </section>
  );
});

export default Home;
