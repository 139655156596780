import React, { useState, useRef, useEffect } from 'react';
import '../assets/stylesheets/Header.scss';

function Header({ handleSectionChange, activeSection, hasScrolled, bgColor, isLoadingSection }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const navRef = useRef(null);

  const toggleMenu = () => {
    if (!isMenuOpen) {
      navRef.current.style.display = 'flex';
    }
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = !isMenuOpen ? 'hidden' : 'auto';
  };


  const handleMenuItemClick = (section) => {
    if (!isLoadingSection) {
      handleSectionChange(section);
      setIsMenuOpen(false);
      document.body.style.overflow = 'auto';
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      setIsVisible(currentScrollY < lastScrollY || currentScrollY < 50);
      setLastScrollY(currentScrollY);
    };
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  useEffect(() => {
    const nav = navRef.current;
    const handleAnimationEnd = () => {
      if (window.screen.width <= 768 && !isMenuOpen) {
        nav.style.display = 'none';
      } else {
        nav.style.display = 'flex';
      }
    };

    nav.addEventListener('animationend', handleAnimationEnd);

    window.addEventListener('resize', () => {
      if (window.screen.width > 768) {
        nav.style.display = 'flex';
      }
    });

    return () => {
      nav.removeEventListener('animationend', handleAnimationEnd);
    };
  }, [isMenuOpen]);

  const isBlueBackground = bgColor === '#7DC6CC';
  const isBlackBackground = bgColor === '#000000';

  return (
    <header className={`${isVisible ? '' : 'hidden'} ${hasScrolled ? 'scrolled' : ''} ${isBlueBackground ? 'blue-bg' : ''} ${isBlackBackground ? 'black-bg' : ''}`} style={hasScrolled ? { backgroundColor: bgColor } : {}}>
      <div className="hamburger-icon" onClick={toggleMenu}>
        <div className={isMenuOpen ? 'icon icon-x' : 'icon icon-hamburger'}></div>
      </div>
      <nav ref={navRef} className={isMenuOpen ? 'nav open' : 'nav close'} id="nav">
        <ul className="nav-list">
          <li className={activeSection === 'home' ? 'active' : ''} onClick={() => handleMenuItemClick('home')}>Home</li>
          <li className={activeSection === 'about' ? 'active' : ''} onClick={() => handleMenuItemClick('about')}>About</li>
          <li className={activeSection === 'portfolio' ? 'active' : ''} onClick={() => handleMenuItemClick('portfolio')}>Portfolio</li>
          <li className={activeSection === 'contact' ? 'active' : ''} onClick={() => handleMenuItemClick('contact')}>Contact</li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
