import React, { useRef, useState } from 'react';
import gsap from 'gsap';
import { Flip } from 'gsap/Flip';
import { useGSAP } from '@gsap/react';
import '../assets/stylesheets/ProjectCardMobile.scss';
import ArrowLeft from './ArrowLeft';
import LinkIcon from './LinkIcon';

gsap.registerPlugin(Flip);

const ProjectCardMobile = ({ project, isExpanded, handleCollapse }) => {
  const projectCardRef = useRef(null);
  const projectCardInfo = useRef(null);
  const projectCardImg = useRef(null);
  const projectCardTypes = useRef(null);
  const projectCardDetails = useRef(null);
  const projectCardArrow = useRef(null);
  const projectCardTech = useRef(null);
  const projectCardHeader = useRef(null);
  const projectCardType = useRef(null);
  const [isCollapsing, setIsCollapsing] = useState(false);

  const renderTechStack = (techStack) => techStack.map((tech, index) => (
    <img src={require(`../assets/icons/tech/${tech}.webp`)} alt={tech} key={tech} className="tech-icon-mb" />
  ));

  useGSAP(() => {
    if (isExpanded) {
      // Ensure initial opacity is set to 0 by GSAP to avoid inline style conflicts
      gsap.set([...projectCardDetails.current.children], { opacity: 0 });

      // Create a timeline for our animations
      const tl = gsap.timeline({ease: "power1.inOut"});

      // Capture the initial state before the expansion
      const state = Flip.getState([projectCardRef.current, projectCardInfo.current, projectCardImg.current, projectCardTypes.current, projectCardHeader.current, projectCardType.current]);

      // Add the class that changes the layout
      projectCardRef.current.classList.add('expanded');
      projectCardHeader.current.classList.remove('text-white');
      [...projectCardTypes.current.children].forEach(child => child.classList.remove('text-white'));


      tl.add(Flip.from(state, { duration: 0.6, scale: false, simple: true, nested: true }))
      .to([...projectCardDetails.current.children], {
        opacity: 1,
        stagger: 0.2,
        duration: 0.3,
        ease: "power2.inOut"
      })
      .to(projectCardArrow.current, {
        opacity: 1,
        duration: 0.3,
        ease: "power2.inOut"
      })
      .to([...projectCardTech.current.children], {
        opacity: 1,
        stagger: 0.05,
        ease: "power2.inOut"
      });
    }
  }, [isExpanded]);

  useGSAP(() => {
    if (isCollapsing) {
      const tl = gsap.timeline({ ease: "power1.inOut" });

      const state = Flip.getState([projectCardRef.current, projectCardInfo.current, projectCardImg.current, projectCardTypes.current, projectCardHeader.current, projectCardType.current]);

      tl.to(projectCardArrow.current, {
        opacity: 0,
        duration: 0.3,
        ease: "power2.inOut"
      })
      .to([...projectCardDetails.current.children], {
        opacity: 0,
        stagger: 0.2,
        duration: 0.3,
        ease: "power2.inOut"
      })
      .add(Flip.from(state, {
        duration: 0.3,
        scale: false,
        simple: true,
        onComplete: () => {
          projectCardRef.current.classList.remove('expanded');
          projectCardHeader.current.classList.add('text-white');
          [...projectCardTypes.current.children].forEach(child => child.classList.add('text-white'));
          handleCollapse(true);
          setIsCollapsing(false);
          gsap.set([...projectCardRef.current.children], { clearProps: "opacity" });
        }
      }), "<");
    }
  }, [isCollapsing]);



  return (
    <div className='project-card-mb' ref={projectCardRef}>
      {isExpanded && <div className="arrow-left-mb" onClick={() => {setIsCollapsing(true)}} ref={projectCardArrow}><ArrowLeft/></div>}
      <div className='project-info-mb' ref={projectCardInfo}>
        <h3 className='header-sm text-white' ref={projectCardHeader}>{project.title}</h3>
        <div className='project-types-mb' ref={projectCardTypes}>
          {project.types.map((type) => (
            <span className="text-body-sm text-white" ref={projectCardType}key={type}>{type}</span>
          ))}
        </div>
      </div>
      <div className='container-img-project-card-mb' ref={projectCardImg}>
        {!isExpanded && <div className='container-img-project-card-overlay'></div>}
        <img src={project.image} alt={project.title}/>
      </div>
      {isExpanded && (
        <div className="project-details-mb" ref={projectCardDetails}>
          <div className="container-project-card-link-mb">
            {project.siteLink && <a href={project.siteLink} className="site-link-mb text-bold" target="_blank" rel="noreferrer" title={`Link to ${project.title}`}>Visit <LinkIcon/></a>}
          </div>
          <p className="project-description-mb text-body-sm">{project.description}</p>
          <h3 className="text-bold">Tech stack</h3>
          <div className="tech-stack-mb" ref={projectCardTech}>
            {renderTechStack(project.techStack)}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectCardMobile;
