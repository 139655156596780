import React, { useState, useEffect, useRef, forwardRef } from 'react';
import ProjectCard from './ProjectCard';
import ProjectCardMobile from './ProjectCardMobile';
import '../assets/stylesheets/Portfolio.scss';
import '../assets/stylesheets/Animations.scss';
import { motion, AnimatePresence } from 'framer-motion';
// import spiritualElixirsBanner from '../assets/images/projects/spiritual-elixirs/spiritualelixirs-banner.webp';
// import railyardBanner from '../assets/images/projects/railyard/railyard-banner.webp';
// import covertBanner from '../assets/images/projects/covert/covert-banner.webp';
// import fanfindBanner from '../assets/images/projects/fanfind/fanfind-banner.webp';
import { gsap } from "gsap";
import { Flip } from "gsap/Flip";

gsap.registerPlugin(Flip);


const Portfolio = forwardRef(({onContactMeClick, spiritualElixirsBanner, railyardBanner, covertBanner, fanFindBanner}, ref) => {
  const [hasOverlay, setHasOverlay] = useState(false);
  const [expandedProjectId, setExpandedProjectId] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const overlayRef = useRef(null);
  const projectCardRef = useRef([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    setIsTouchDevice(('ontouchstart' in window) || navigator.maxTouchPoints > 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const projects = [
    {
      id: 1,
      title: 'Spiritual Elixirs',
      types: ['Development', 'UX/UI Design'],
      image: spiritualElixirsBanner,
      video: 'path-to-video',
      description: 'E-commerce web application for Spiriual Elixirs - a tequila manufacturing, branding, and distribution company.',
      siteLink: null,
      ghLink: null,
      techStack:  ['Figma', 'HTML', 'CSS', 'Sass', 'JavaScript', 'React', 'Ruby on Rails', 'SQL']
    },
    {
      id: 2,
      title: 'RailYard',
      types: ['Development', 'UX/UI Design', 'Graphic Design'],
      image: railyardBanner,
      video: 'path-to-video',
      description: 'Web application that connects Rails developers around the world. Users can create a profile, post projects, and collaborate with other developers. This project won 6th place in the 2023 Rails Hackathon.',
      siteLink: 'https://enigmatic-sea-22620-a039438de6c0.herokuapp.com/',
      ghLink: null,
      techStack:  ['Figma', 'HTML', 'CSS', 'Sass', 'JavaScript', 'Ruby on Rails', 'SQL']
    },
    {
      id: 3,
      title: 'Covert',
      types: ['Development', 'UX/UI Design'],
      image: covertBanner,
      video: 'path-to-video',
      description: 'Interactive map to show vegetation levels and vertical gardens across the different districts of Barcelona. This project was a finalist in the 2023 Barcelona Tech4Good Hackathon organized by Barcelona Activa and the Mobile World Capital Foundation.',
      siteLink: 'https://covert-one.vercel.app/',
      ghLink: null,
      techStack: ['Figma', 'HTML', 'CSS', 'Sass', 'React']
    },
    {
      id: 4,
      title: 'FanFind',
      types: ['Development', 'UX/UI Design', 'Graphic Design'],
      image: fanFindBanner,
      video: 'path-to-video',
      description: 'Progressive web application (PWA) that allowed users to connect during the 2022 Fifa World Cup to watch the matches together.',
      siteLink: null,
      ghLink: null,
      techStack: ['Figma', 'HTML', 'CSS', 'Sass', 'JavaScript', 'Ruby on Rails', 'SQL']
    }
  ];

  const handleMouseMove = (event) => {
    const eyes = document.querySelectorAll('.eye');
    eyes.forEach(eye => {
      const { left, top, width, height } = eye.getBoundingClientRect();
      const eyeCenterX = left + width / 2;
      const eyeCenterY = top + height / 2;
      const mouseX = event.clientX;
      const mouseY = event.clientY;
      const deltaX = mouseX - eyeCenterX;
      const deltaY = mouseY - eyeCenterY;
      const angle = Math.atan2(deltaY, deltaX);
      const radius = Math.min(width, height) / 4;
      const pupilX = radius * Math.cos(angle) / 2;
      const pupilY = radius * Math.sin(angle) / 2;
      const pupil = eye.firstChild;
      pupil.style.transform = `translate(${pupilX}px, ${pupilY}px)`;
    });
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleClick = (project) => {
    const body = document.querySelector('body');
    body.style.overflow = 'hidden';

    const id = project.id;

    const state = Flip.getState(projectCardRef.current[project.id]);

    setHasOverlay(true);

    setTimeout(() => {
      const overlay = document.querySelector('.overlay');
      overlay.appendChild(projectCardRef.current[project.id]);

      Flip.from(state, {
        targets: projectCardRef.current[project.id],
        duration: 0.8,
        ease: "power3.inOut",
        absolute: true,
        simple: true,
        scale: false,
        onComplete: () => {
          setExpandedProjectId(id);
          setIsExpanded(true);
        }
      });
    }, 0);
  };


  const handleCollapse = (bool) => {
    if (bool && expandedProjectId) {
      const state = Flip.getState(projectCardRef.current[expandedProjectId]);

      const container = document.getElementById('container-projects');
      const projectIndex = projects.findIndex(project => project.id === expandedProjectId);
      const nextProject = projects[projectIndex + 1];
      const nextProjectElement = projectCardRef.current[nextProject ? nextProject.id : null];

      if (nextProjectElement && container) {
        container.insertBefore(projectCardRef.current[expandedProjectId], nextProjectElement);
      } else {
        container.appendChild(projectCardRef.current[expandedProjectId]);
      }

      setTimeout(() => {
        Flip.from(state, {
          targets: projectCardRef.current[expandedProjectId],
          duration: 0.8,
          ease: "power3.inOut",
          absolute: true,
          simple: true,
          scale: false,
          onComplete: () => {
            setIsExpanded(false);
            setHasOverlay(false);
            setExpandedProjectId(null);
            const body = document.querySelector('body');
            body.style.overflow = 'auto';
          }
        });
      }, 0);
    }
  };


  return (
    <section id="portfolio">
      <div id="container-portfolio-a">
        <div className="container-header">
            <motion.div
              initial={{ y: "100%" }}
              whileInView={{ y: "0%" }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.5}}>
              <h1 className="header-lg">Portfolio</h1>
            </motion.div>
          </div>
        <p className="subheader-md">Check out some of my work.</p>
        <div id="container-projects">
          {projects.map((project) => (
            <div className="wrapper-project-card" ref={(el) => projectCardRef.current[project.id] = el}
            onClick={() => handleClick(project)}>
              {isMobile ? (
              <ProjectCardMobile
                key={project.id}
                project={project}
                anim={hasOverlay ? undefined : `slide-in-${project.id % 2 === 0 ? 'right' : 'left'}`}
                isExpanded={isExpanded ? project.id === expandedProjectId : false}
                handleCollapse={handleCollapse}
              />)
              :
              (<ProjectCard
                key={project.id}
                project={project}
                anim={hasOverlay ? undefined : `slide-in-${project.id % 2 === 0 ? 'right' : 'left'}`}
                orientation={project.id % 2 === 0 ? 'right' : 'left'}
                isExpanded={isExpanded ? project.id === expandedProjectId : false}
                handleCollapse={handleCollapse}
              />
              )}
            </div>
          ))}
      </div>
      </div>
      <div id="container-portfolio-b">
        <div id="container-portfolio-b-info">
          <h2 className="header-md">Like what you see?</h2>
          <p className="subheader-md">Let's get in touch!</p>
          <div id="container-btn-portfolio">
            <button onClick={onContactMeClick} className="btn-white-rounded">Contact Me</button>
          </div>
        </div>
        {(!isMobile && !isTouchDevice) && (
          <div id="container-portfolio-b-eyes" onMouseMove={handleMouseMove}>
            <div className="eye">
              <div className="pupil"></div>
              <span className="light"></span>
              <span className="ring"></span>
            </div>
            <div className="eye">
              <div className="pupil"></div>
              <span className="light"></span>
              <span className="ring"></span>
            </div>
          </div>
        )}
        </div>
      <AnimatePresence>
        {hasOverlay && (
          <motion.div
            className="overlay"
            initial={{ opacity: 0, zIndex: -1 }}
            animate={{ opacity: 1, zIndex: 6 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.6 }}
            key="overlay"
            ref={overlayRef}
          >
          </motion.div>
        )}
      </AnimatePresence>

    </section>
  );
});

export default Portfolio;
