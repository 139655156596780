import React from 'react';
import '../assets/stylesheets/Footer.scss';
import logo from '../assets/images/logo/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faGithub, faDribbble } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  const date = new Date();
  let year = date.getFullYear();

  return (
    <footer className="sticky-footer">
      <div className="footer-links">
        <img src={logo} alt="Emilia Rojo" />
        <a href="https://www.linkedin.com/in/emilia-rojo/" target="_blank" rel="noreferrer" title="Link to my LinkedIn page">
          <span className="text-link">LinkedIn</span>
          <FontAwesomeIcon icon={faLinkedinIn} className="icon-link" />
        </a>
        <a href="https://github.com/emiliarojo" target="_blank" rel="noreferrer" title="Link to my GitHub page">
          <span className="text-link">GitHub</span>
          <FontAwesomeIcon icon={faGithub} className="icon-link" />
        </a>
        <a href="https://dribbble.com/emiliarojo" target="_blank" rel="noreferrer" title="Link to my Dribbble page">
          <span className="text-link">Dribbble</span>
          <FontAwesomeIcon icon={faDribbble} className="icon-link" />
        </a>
      </div>
      <p>© {year} Emilia Rojo</p>
    </footer>
  );
};

export default Footer;
