import React, { useState, useEffect, startTransition } from 'react';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"
import './assets/stylesheets/App.scss';
import { Helmet } from 'react-helmet';

import Loading from './components/Loading';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';

import { useInView } from 'react-intersection-observer';
import { CSSTransition } from 'react-transition-group';

import homeImage from './assets/images/home/home-img.webp';
import aboutMeImage from './assets/images/about/me.webp';
import covertImage from './assets/images/projects/covert/covert-banner.webp';
import fanFindImage from './assets/images/projects/fanfind/fanfind-banner.webp';
import railyardImage from './assets/images/projects/railyard/railyard-banner.webp';
import spiritualElixirsImage from './assets/images/projects/spiritual-elixirs/spiritualelixirs-banner.webp';

function preloadImages(images) {
  return images.map((imageSrc) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imageSrc;
      img.onload = resolve;
      img.onerror = reject;
    });
  });
}


function App() {
  const [activeSection, setActiveSection] = useState('home');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isMobileLandscape, setIsMobileLandscape] = useState(window.innerHeight < 480);
  const metaTags = {
    title: "Emilia Rojo - Designer & Developer",
    description: "Dive into my portfolio to explore my projects, skills, and professional journey.",
    image: "https://www.emiliarojo.com/meta.png",
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsMobileLandscape(window.innerHeight < 480);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false); // New state to manage animation
  const [preloadedImages, setPreloadedImages] = useState({});

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = 'hidden';

    const imageList = [
      homeImage,
      aboutMeImage,
      covertImage,
      fanFindImage,
      railyardImage,
      spiritualElixirsImage,
    ];

    Promise.all([...preloadImages(imageList)])
      .then(() => {
        const imagesObject = {
          homeImage: homeImage.src,
          aboutMeImage: aboutMeImage,
          covertImage: covertImage,
          fanFindImage: fanFindImage,
          railyardImage: railyardImage,
          spiritualElixirsImage: spiritualElixirsImage,
        };
        setPreloadedImages(imagesObject);
        console.log('All assets are loaded');
        setTimeout(() => {
          setIsAnimating(true);
          setTimeout(() => {
            setIsLoading(false);
            body.style.overflow = 'auto';
          }, 400);
        }, 2000);
      })
      .catch((error) => console.error("Error preloading images:", error));
  }, []);



  const homeLandscapeView = isMobileLandscape ? {threshold: 0.5} : {threshold: 0.7};
  const aboutLanscapeView = isMobileLandscape ? {threshold: 0.2} : {threshold: 0.7};

  const [homeRef, inViewHome] = useInView({ ...homeLandscapeView, triggerOnce: false });
  const [aboutRef, inViewAbout] = useInView({ ...aboutLanscapeView, triggerOnce: false });

  const handleSectionChange = (section) => {
    startTransition(() => {
      setActiveSection(section);
    });
  };

  const onLearnMoreClick = () => {
    handleSectionChange('about');
    window.scrollTo(0, 0);
  };

  const onViewPortfolioClick = () => {
    handleSectionChange('portfolio');
    window.scrollTo(0, 0);

  };

  const onContactMeClick = () => {
    handleSectionChange('contact');
    window.scrollTo(0, 0);
  };

  const getBackgroundColor = () => {
    const sectionColors = {
      home: inViewHome ? '#7DC6CC' : '#FCFCFC',
      about: inViewAbout ? '#7DC6CC' : '#000000',
      portfolio: '#FCFCFC',
      contact: '#FCFCFC'
    };
    return sectionColors[activeSection];
  };

  const getContainerStyle = () => {
    switch (activeSection) {
      case 'portfolio':
        return { background: isMobile ? 'linear-gradient(180deg, #FCFCFC 86%, #7DC6CC 100%)': 'linear-gradient(180deg, #FCFCFC 80%, #7DC6CC 100%)' };
      case 'home':
        return { backgroundColor: inViewHome ? '#7DC6CC' : '#FCFCFC' };
      case 'about':
        return { backgroundColor: inViewAbout ? '#7DC6CC' : '#000000' };
      case 'contact':
        return { backgroundColor: '#FCFCFC' };
      default:
        return { backgroundColor: '#FCFCFC' };
    }
  };

  const [hasScrolled, setHasScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const renderSection = () => {
    return (
        <div className="section-container">
          <CSSTransition in={activeSection === 'home'} classNames="fade" unmountOnExit>
            <div className={activeSection === 'home' ? 'active' : ''}>
              <Home ref={homeRef} onLearnMoreClick={onLearnMoreClick} homeImage={homeImage} />
            </div>
          </CSSTransition>
          <CSSTransition in={activeSection === 'about'} classNames="fade" unmountOnExit>
            <div className={activeSection === 'about' ? 'active' : ''}>
              <About ref={aboutRef} onViewPortfolioClick={onViewPortfolioClick}  meImage={preloadedImages.aboutMeImage} />
            </div>
          </CSSTransition>
          <CSSTransition in={activeSection === 'portfolio'} classNames="fade" unmountOnExit>
            <div className={activeSection === 'portfolio' ? 'active' : ''}>
              <Portfolio onContactMeClick={onContactMeClick}
                spiritualElixirsBanner={preloadedImages.spiritualElixirsImage}
                railyardBanner={preloadedImages.railyardImage}
                covertBanner={preloadedImages.covertImage}
                fanFindBanner={preloadedImages.fanFindImage}
              />
            </div>
          </CSSTransition>
          <CSSTransition in={activeSection === 'contact'} classNames="fade" unmountOnExit>
            <div className={activeSection === 'contact' ? 'active' : ''}>
              <Contact />
            </div>
          </CSSTransition>
        </div>

    );
  };

  return (
    <div className="app-container" style={getContainerStyle()}>
      <Helmet>
        <title>{metaTags.title}</title>
        <meta name="description" content={metaTags.description} />
        <meta property="og:title" content={metaTags.title} />
        <meta property="og:description" content={metaTags.description} />
        <meta property="og:image" key="og:image" content={metaTags.image} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTags.title} />
        <meta name="twitter:image" key="twitter:image" content={metaTags.image} />
      </Helmet>
      {isLoading && (
        <Loading isLoadingComplete={isAnimating} />
      )}
        <Header
          handleSectionChange={handleSectionChange}
          activeSection={activeSection}
          hasScrolled={hasScrolled}
          bgColor={getBackgroundColor()}
        />
        {renderSection()}
        <Footer />

      <Analytics />
      <SpeedInsights />
    </div>
  );
}

export default App;
